
import { reactive, toRefs, defineComponent } from "vue";
import Container from "@/components/atoms/Container.vue";

export default defineComponent({
  components: { Container },
  setup() {
    const state = reactive({
      count: 0
    });

    return {
      ...toRefs(state)
    };
  }
});
