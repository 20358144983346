
import { defineComponent } from "vue";
import BigButton from "@/components/atoms/BigButton.vue";
import Icon from "@/components/atoms/Icon.vue";

export default defineComponent({
  components: { BigButton, Icon },
  setup() {
    return {};
  }
});
