
import { defineComponent, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { User } from "@/model/State.ts";

export default defineComponent({
  props: { isProfileOpen: { type: Boolean } },

  setup() {
    const store = useStore();
    const isUserAdmin = ref(false);
    const jwtKey = computed(() => store.state.jwt);
    function getUserRole() {
      isUserAdmin.value = false;
      // store.getters.getMe.then((result: User) => {
      //   if (!result) return;
      //   const roleId = result.role.id;
      //   if (roleId === 1) {
      //     isUserAdmin.value = true;
      //   }
      // });
    }
    getUserRole();
    watch(jwtKey, () => {
      getUserRole();
    });
    return { isUserAdmin };
  }
});
