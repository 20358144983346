
import { defineComponent, ref, PropType } from "vue";

export default defineComponent({
  // inheritAttrs: false,
  props: {
    label: String,
    id: String,
    info: String,
    error: Boolean,
    success: Boolean,
    fullWidth: Boolean,
    light: Boolean,
    modelValue: String as PropType<string | number>
  },
  methods: {
    handleChange(event: Event) {
      if (!(event.target instanceof HTMLInputElement)) return;
      this.$emit("update:modelValue", event.target.value);
    }
  },
  setup() {
    return {};
  }
});
