<template>
  <div class="lg:flex">
    <div class="lg:w-1/2 xl:max-w-screen-sm">
      <div
          class="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12"
      ></div>
      <div
          class="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl"
      >
        <form class="form" @submit.prevent="handleSubmit">
          <h2
              class="text-center text-4xl text-indigo-900 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
          >
            Sign Up
          </h2>
    <base-input
        fullWidth
        v-model="username"
        :error="errors.username"
        label="Username"
        id="register-username"
    />
    <base-input
        fullWidth
        v-model="email"
        :error="errors.email"
        label="E-mail"
        type="email"
        id="register-email"
    />
    <base-input
        fullWidth
        v-model="pw"
        :error="errors.pw"
        label="Password"
        type="password"
        id="register-pw"
    />
    <base-input
        fullWidth
        v-model="rpw"
        :error="errors.pw"
        label="Repeat password"
        type="password"
        id="register-rpw"
    />
    <label :class="{ 'text-red-500': errors.agreement }">
      <input
          id="register-agreement"
          v-model="agreement"
          class="mr-4"
          type="checkbox"
      />
      I agree to terms and conditions.</label
    >
          <div class="mt-10">
            <button :disabled="isLoading"
                    class="bg-primary text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
            >
              <spinner class="spinner-sm" v-if="isLoading" />
              <span v-else>
        Register
      </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, watch, toRefs } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import router from "@/router";

export default defineComponent({
  setup() {
    const store = useStore();

    const formData = reactive({
      username: "",
      email: "",
      pw: "",
      rpw: "",
      agreement: false,
      errors: {
        username: false,
        email: false,
        pw: false,
        agreement: false
      }
    });
    const isLoading = ref(false);
    const resolved = computed(() => store.state.toasts.length);
    watch(resolved, val => {
      if (val) isLoading.value = false;
    });
    function resetErrors() {
      formData.errors.username = false;
      formData.errors.email = false;
      formData.errors.pw = false;
      formData.errors.agreement = false;
    }

    function validateForm() {
      resetErrors();
      const errors = [];

      // has user agreed?
      if (!formData.agreement) {
        errors.push("agreement");
      }
      // are passwords equal
      if (
          formData.pw.trim() !== formData.rpw.trim() ||
          formData.pw.trim().length < 5
      ) {
        errors.push("pw");
      }
      // is email valid
      const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      if (!emailRegex.test(formData.email)) {
        errors.push("email");
      }
      // is username more than 4 letters
      if (formData.username.trim().length <= 4) {
        errors.push("username");
      }

      if (errors.length) {
        errors.forEach(field => {
          const index = field as keyof typeof formData.errors;
          formData.errors[index] = true;
        });

        return false;
      }

      return true;
    }

    function handleSubmit() {
      isLoading.value = true;
      const isValid = validateForm();
      if (!isValid) return;

      store.dispatch("register", {
        username: formData.username,
        password: formData.pw,
        email: formData.email
      });

      router.push('/');
    }
    return { ...toRefs(formData), handleSubmit, isLoading };
  }
});
</script>

<style lang="postcss" scoped>
.form {
  @apply flex flex-col gap-4;
}
</style>
