import { createStore } from "vuex";
import {Product, Hero, CartItem, User} from "@/model/State";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export const store = createStore<State>({
  state: {
    products: [],
    cart: [],
    confirmation:false,
    jwt: "",
    categories: [],
    user: [],
    toasts: [],
    favourites: [],
    filtered_products:[],
    hero: null,
    modal: null
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {}
});

interface ErrorItem {
  type: "primary" | "error";
  icon: string;
  message: string;
}

export type State = {
  products: Product[];
  filtered_products: [];
  cart: CartItem[];
  confirmation: boolean;
  jwt: string;
  categories: [];
  user: User[];
  toasts: ErrorItem[];
  favourites: number[];
  hero: Hero | null;
  modal: string | null;
};
