
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    error: {
      type: Boolean as PropType<boolean>,
    },
    icon: {
      type: String as PropType<string>,
    },
    toastId: {
      type: Number,
    },
  },

  data() {
    return {
      show: true,
      counter: setTimeout('', 0),
    };
  },

  methods: {
    killSelf() {
      clearTimeout(this.counter);
      this.$store.commit('removeToast', this.toastId);
      this.show = false;
    },
  },

  beforeMount() {
    this.counter = setTimeout(this.killSelf, 3000);
  },

  beforeUnmount() {
    clearTimeout(this.counter);
  },
  setup() {
    return {};
  },
});
