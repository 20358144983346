<template>
  <div class="lg:flex">
    <div class="lg:w-1/2 xl:max-w-screen-sm">
      <div
          class="py-12 bg-indigo-100 lg:bg-white flex justify-center lg:justify-start lg:px-12"
      ></div>
      <div
          class="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl"
      >
  <form class="form" @submit.prevent="handleSubmit">
    <h2
        class="text-center text-4xl text-indigo-900 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
    >
      Sign In
    </h2>
    <base-input
        fullWidth
        label="Phone"
        v-model="login"
        id="register-username"
    />
    <base-input
        fullWidth
        label="Password"
        v-model="password"
        type="password"
        id="register-pw"
    />

    <div class="mt-10">
      <button :disabled="isLoading"
          class="bg-primary text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
      >
        <spinner class="spinner-sm" v-if="isLoading" />
        <span v-else>
        Log in
      </span>
      </button>
    </div>
  </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

export default defineComponent({
  data() {
    return {
      login: "",
      password: ""
    };
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const resolved = computed(() => store.state.toasts.length);
    watch(resolved, val => {
      if (val) isLoading.value = false;
    });
    return {
      isLoading
    };
  },
  methods: {
    handleSubmit() {
      const form = new FormData();
      form.append("phone",this.login.trim());
      form.append("password",this.password.trim());
      this.$store.dispatch("authorize", form);

      this.isLoading = true;
      // router.push('/');
    }
  }
});
</script>

<style lang="postcss" scoped>
.form {
  @apply flex flex-col gap-4;
}
</style>
