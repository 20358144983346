
import { defineComponent } from "vue";
import Container from "@/components/atoms/Container.vue";
// import Hero from "@/components/molecules/Hero.vue";
import ProductGrid from "@/components/organisms/ProductGrid.vue";

export default defineComponent({
  name: "Home",
  computed: {
    categories() {
      return this.$store.state.categories;
    }
  },
  components: {
    // Hero,
    Container,
    ProductGrid
  },
  data() {
    return {
      items:[],
      category:"",
      category_selected:true
    };
  },

  methods:{
    getByCategory(cat: string)
    {
      this.items = this.$store.getters.getProductByCategory(cat);
      this.category_selected = false;
    }
  }

});
