import {MutationTree} from "vuex";
import {State} from "./index";
import {Product} from "@/model/State";

export const lsKeys = {
  favourites: "soko-fav",
  jwt: "soko-token",
  cart: "soko-basket"
};

const MAX_AMOUNT_TOASTS = 3;

const mutations: MutationTree<State> = {
  setProducts(state, payload) {
    state.products = payload;
  },
  setCategories(state,payload)
  {
    function onlyUnique(value: string, index: number, self: any) {
      return self.indexOf(value) === index;
    }

    state.categories = payload.map((value: { category_name: any }) => value.category_name).filter( onlyUnique );//Array.from( new Set( payload.map((value: { category_name: any }) => value.category_name) ) ); //[...new Set(categories)]
  },
  addToCart(state, payload) {
    if (state.cart.some(item => item.id === payload.product_id)) return;
    const cartItem = { quantity:1,product_rate: payload.price,product_id:payload.product_id, id: payload.product_id };
    state.cart.push(cartItem);
    localStorage.setItem(lsKeys.cart, JSON.stringify(state.cart));
    // if (state.cart.some(item => item.product_id == payload)) return;
    // // const cartItem = [{ amount: 1, id: payload }];
    // // const cartItem = { amount: 1, id: payload };
    // // state.cart.push(cartItem);
    // state.cart=[{ amount: 1,product_id:payload, id: payload }];
    // localStorage.setItem(lsKeys.cart, JSON.stringify(state.cart));
  },

  increamentCartQty(state, payload) {
    const findProduct = state.cart.find(o => o.id === payload.product_id)
    if(findProduct){
      if (findProduct.quantity < 1)
      {
        findProduct.quantity  = 1;
      }else
      {
        findProduct.quantity +=1;
      }
      localStorage.setItem(lsKeys.cart, JSON.stringify(state.cart));
      return;
    }
  },

  decreamentCartQty(state, payload) {
    const findProduct = state.cart.find(o => o.id === payload.product_id)
    if(findProduct){
      if (findProduct.quantity < 1)
      {
        findProduct.quantity  = 1;
      }else
      {
        findProduct.quantity -=1;
      }

      localStorage.setItem(lsKeys.cart, JSON.stringify(state.cart));
      return;
    }
  },

  removeFromCart(state, payload) {
    const indexToRemove = state.cart.findIndex(item => item.id == payload);
    if (indexToRemove < 0) return;
    state.cart.splice(indexToRemove, 1);
    localStorage.setItem(lsKeys.cart, JSON.stringify(state.cart));
  },
  clearCart(state, payload) {
    state.cart=[];
    localStorage.setItem(lsKeys.cart, JSON.stringify(state.cart));
  },
  setCachedCart(state) {
    const cachedCart = localStorage.getItem(lsKeys.cart);
    if (!cachedCart) return;
    state.cart = JSON.parse(cachedCart);
  },
  addToast(state, payload) {
    if (state.toasts.length >= MAX_AMOUNT_TOASTS) state.toasts.splice(0, 1);
    state.toasts.push(payload);
  },
  setHero(state, payload) {
    state.hero = payload;
  },
  setModal(state, payload) {
    state.modal = payload;
  },
  removeToast(state, payload) {
    if (payload >= state.toasts.length) return;

    state.toasts.splice(payload, 1);
  },
  addFavourite(state, payload) {
    if (state.favourites.includes(payload)) return;
    state.favourites.push(payload);
    localStorage.setItem(lsKeys.favourites, JSON.stringify(state.favourites));
  },

  removeFavourite(state, payload) {
    const indexToRemove = state.favourites.findIndex(id => id == payload);
    if (indexToRemove < 0) return;
    state.favourites.splice(indexToRemove, 1);
    localStorage.setItem(lsKeys.favourites, JSON.stringify(state.favourites));
  },
  setCachedJWTToken(state) {
    const cachedToken = localStorage.getItem(lsKeys.jwt);
    if (!cachedToken) return;
    state.jwt = cachedToken;
  },
  setJWTToken(state, payload) {
    state.jwt = payload;
    localStorage.setItem(lsKeys.jwt, state.jwt);
  },
  setUser(state, payload) {
    state.user = payload;
    localStorage.setItem("user", JSON.stringify(state.user));
  },

  clearJWTToken(state) {
    state.user = [];
    localStorage.removeItem(lsKeys.jwt);
  },
  getCachedFavourites(state) {
    const cachedFavourites = localStorage.getItem(lsKeys.favourites);
    if (!cachedFavourites) return;
    state.favourites = JSON.parse(cachedFavourites);
  }
};

export default mutations;
