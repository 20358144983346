<template>
<!--  <hero />-->
  <container>
    <section class="text-gray-600 body-font" v-if="category_selected">
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Product Categories</h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Pick a category to display products within it.</p>
        </div>
        <div class="flex flex-wrap -m-2">
          <div v-for="(category,index) in categories" :key="index" @click="getByCategory(category)" class="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div class="h-full flex items-center justify-center border-gray-200 border p-4 rounded-lg">
              <div class="flex-grow">
                <h2 class="text-gray-900 text-center title-font font-medium">{{category}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <product-grid v-else title="All Products" :items="items"> </product-grid>
  </container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Container from "@/components/atoms/Container.vue";
// import Hero from "@/components/molecules/Hero.vue";
import ProductGrid from "@/components/organisms/ProductGrid.vue";

export default defineComponent({
  name: "Home",
  computed: {
    categories() {
      return this.$store.state.categories;
    }
  },
  components: {
    // Hero,
    Container,
    ProductGrid
  },
  data() {
    return {
      items:[],
      category:"",
      category_selected:true
    };
  },

  methods:{
    getByCategory(cat: string)
    {
      this.items = this.$store.getters.getProductByCategory(cat);
      this.category_selected = false;
    }
  }

});
</script>

<style lang="scss" scoped></style>
