
import Icon from "@/components/atoms/Icon.vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    variant: String,
    size: String,
    fullWidth: Boolean,
    outline: Boolean,
    icon: String,
    iconWhite: Boolean
  }
});
