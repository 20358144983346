
import { defineComponent, PropType, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { Product } from '@/model/State';
import Icon from '@/components/atoms/Icon.vue';

export default defineComponent({
  components: { Icon },
  props: {
    image: {
      type: String as PropType<string>,
      required: true
    },
    data: {
      type: Object as PropType<Product>,
      required: true
    },
    editable: {
      type: Boolean as PropType<boolean>
    },
    hideFavIcon: {
      type: Boolean as PropType<boolean>
    }
  },
  setup(props) {
    const store = useStore();
    const confirmDelete = ref(false);
    const favourite = computed(() =>
      store.state.favourites.includes(props.data.id)
    );
    const api = computed(() => process.env.VUE_APP_API_URL);
    return { api, favourite, confirmDelete };
  }
});
