
import { defineComponent, ref } from "vue";
import MoreButton from "@/components/molecules/MoreButton.vue";
import ProductCard from "@/components/molecules/ProductCard.vue";

export default defineComponent({
  components: { MoreButton, ProductCard },
  props: {
    editable: Boolean,
    items: Array,
    title: String,
    moreHref: String,
    moreText: String
  },
  setup(props) {
    const btnText = ref(props.moreText || "View more products");
    const btnHref = ref(props.moreHref || "/products");
    return {
      btnText,
      btnHref
    };
  }
});
