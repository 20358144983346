import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { store } from "@/store";
import Home from "../views/Home.vue";
import Login from "../views/login.vue";
import Register from "../views/Register.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      requiresAuth: true
    },
    component: Home
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/products/:category?",
    name: "Products",
    component: () =>
      import(/* webpackChunkName: "allproducts" */ "../views/AllProducts.vue")
  },
  {
    path: "/product/:id",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "allproducts" */ "../views/Product.vue")
  },
  {
    path: "/dashboard/:page?/:id?",
    name: "Dashboard",
    meta: {
      requiresAdmin: true
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/cart",
    name: "Cart",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Cart.vue")
  },
  {
    path: "/favourites",
    name: "Favourites",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Favourites.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Error.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);
  const reqAdmin = to.matched.some(record => record.meta.requiresAdmin);

  // if (!reqAuth && !reqAdmin) return next();

  if (!store.getters.getUser && to.name !== 'Login' ){
    store.commit("addToast", {
      type: "error",
      message: "Auth error: User not logged in"
    });

    // console.log(await store.getters.getMe)
    // store.commit('setModal', 'login')
    next({ name: 'Login' })
    // next({ name: 'Login' })
    // return
    // return next(false);
    // next();
  }

  next();

  // if (reqAdmin) {
  //   try {
  //     const userData = await store.getters.getMe;
  //
  //     const roleId = await userData.role.id;
  //     if (roleId !== 1) throw new Error("User is not admin");
  //     return next();
  //   } catch (error) {
  //     store.commit("addToast", {
  //       type: "error",
  //       message: "Auth error: " + error.message
  //     });
  //     next(false);
  //   }
  // }
  // if (reqAuth) {
  //   try {
  //     const userData = await store.getters.getMe;
  //
  //     const isConfirmed = userData.confirmed;
  //     const isBlocked = await userData.blocked;
  //     if (!userData) throw new Error("User not logged in");
  //     if (!isConfirmed) throw new Error("User not confirmed");
  //     if (isBlocked) throw new Error("User is banned");
  //     next();
  //   } catch (error) {
  //     store.commit("addToast", {
  //       type: "error",
  //       message: "Auth error: " + error.message
  //     });
  //     store.commit('setModal', 'login')
  //     // console.log('login')
  //     // next({ name: 'Login' })
  //     // return
  //     next();
  //   }
  // }
});

export default router;
