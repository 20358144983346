
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: String,
    info: String,
    error: Boolean,
    success: Boolean,
    fullWidth: Boolean,
    light: Boolean,
    options: Object,
    modelValue: String
  },
  emits: ["update:modelValue"],
  methods: {
    handleChange(event: Event) {
      if (!(event.target instanceof HTMLSelectElement)) return;
      this.$emit("update:modelValue", event.target.value);
    }
  },
  setup() {
    return {};
  }
});
