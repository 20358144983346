
import { defineComponent} from "vue";
import UserPopup from "@/components/popups/UserPopup.vue";
import BaseButton from "@/components/atoms/BaseButton.vue";

export default defineComponent({
  components: {BaseButton, UserPopup },
  name: "Navigation",
  methods: {
    triggerMenu() {
      this.isNavOpen = !this.isNavOpen;
    },
  },
  computed:
  {
    totalProducts () {

      const tot: number = this.$store.state.cart.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0)
      return tot;
  }
  },

  data() {
    return {
      isNavOpen: false,
      isProfileOpen: false,
      isUserAdmin: false
    };
  },

  props: {
    routes: Array
  }
});
