
import Icon from "@/components/atoms/Icon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Icon
  },
  data() {
    return {};
  },

  props: { variant: String, icon: String, outline: Boolean }
});
